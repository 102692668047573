@import '../../../style/text.scss';
@import '../../../style/interaction.scss';

.select {
  @include text_body;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  flex-shrink: 1;
  align-self: stretch;
  color: inherit;
  margin: 0;
  border-width: 0;
  border-radius: 0.25rem;
  background-color: transparent;
  background-image: var(--gradient);
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  @include button_hover;

  option {
    color: initial;
    font-weight: normal;
  }
}

.select::-ms-expand {
  display: none;
}

.select:focus {
  border-color: transparent;
  outline: none;
}
