@import '_styles/colors.scss';

.layout {
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  right: 2rem;
  bottom: 1rem;
  width: auto;
  padding: 1rem;
  background-color: RGB(var(--theme_background_module));
  transition: transform 0.5s ease-in;
  transform: translateY(6rem);
  z-index: 1;
  @include modal_shadow;

  & > * {
    align-self: stretch;
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }

  &.in {
    animation-duration: 0.5s;
    animation-name: slideinAction;
    animation-fill-mode: forwards;
  }

  &.out {
    animation-duration: 0.5s;
    animation-name: slideoutAction;
    animation-fill-mode: forwards;
  }
}

@keyframes slideinAction {
  from {
    transform: translateY(6rem);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes slideoutAction {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(6rem);
  }
}