@import '../../../style/interaction.scss';

.layout {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;
  width: 1rem;
  height: 1rem;
  background-color: RGB(var(--theme_button_disabled));
  border-width: 0;
  border-radius: 0.25rem;
  @include button_hover;

  &.tag {
    background-color: RGB(var(--theme_text));
  }

  &.selected {
    background-color: RGB(var(--theme_button));
  }

  &.disabled {
    background-color: RGB(var(--theme_button_disabled));
    pointer-events: none;
  }
}

.rounded {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;
  width: 1rem;
  height: 1rem;
  background-color: RGB(var(--theme_button_disabled));
  border-width: 0;
  border-radius: 100%;
  @include button_hover;

  &.selected {
    background-color: RGB(var(--theme_button));

    &::after {
      content: '';
      width: 0.5rem;
      height: 0.5rem;
      border-width: 0;
      border-radius: 100%;
      background-color: RGB(var(--theme_text_light));
    }
  }

  &.disabled {
    background-color: RGB(var(--theme_button_disabled));
    pointer-events: none;
  }
}