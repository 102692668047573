@import '_styles/colors.scss';
@import '_styles/text.scss';
@import '_styles/boxes.scss';

.layout {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .caddy {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translateX(100%);
    animation-duration: 0.5s;
    animation-name: slidein;
    animation-fill-mode: forwards;

    &.out {
      animation-duration: 0.5s;
      animation-name: slideout;
      animation-fill-mode: forwards;
    }

    .content {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: RGB(var(--theme_background_ui));
      @include modal_shadow;
      @include scroll_bar;

      .controls {
        position: absolute;
        bottom: 5rem;
        left: -10rem;
        width: 10rem;
        padding: 2rem 1rem 1rem 1.5rem;
        background-color: RGB(var(--theme_background_module));

        & > * {
          align-self: stretch;
          margin-bottom: 1rem;
        }

        .required {
          position: absolute;
          top: 0.75rem;
          left: 1.5rem;
          right: 1rem;
          font-size: 0.75rem;
          text-align: center;
        }

        .lockMessage {
          text-align: center;
        }
      }
    }

    .scroller {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      overflow: auto;
      @include scroll_bar;
    }
  }
}

@keyframes slidein {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes slideout {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(100%);
  }
}

:global(#slide) {
  & > *:last-child {
    pointer-events: all;
  }

  & > *:nth-child(1) {
    .content {
      width: calc(100% - 11rem);
    }
  }

  & > *:nth-child(2) {
    .content {
      width: calc(100% - 14rem);
    }
  }

  & > *:nth-child(3) {
    .content {
      width: calc(100% - 17rem);
    }
  }

  & > *:nth-child(4) {
    .content {
      width: calc(100% - 21rem);
    }
  }
}